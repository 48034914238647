import React from "react";
import { Link } from 'react-router-dom';
import logoCircle from "../LogoCircle.svg";
import '../App.css';

function Privacy() {
    return (
        <div className="App-content">
            <Link to="/" className="link">
                <img src={logoCircle} className="logo-circle" alt="logo" />
            </Link>
            <h1>Privacy Policy</h1>
            <p>Your privacy is extremely important to us. That is why we do our best to protect the data you have entrusted with us.
                Click collects any information you give us directly or information that we obtain from your public Facebook profile
                if you decide to link your account. Furthermore, to enhance your Click experience and to provide you with the best
                possible Service, we use cookies, pixel tags, and device identifiers to collect information.

            </p>
            <h2>User Information</h2>
            <p>Click collects information about your activities while using our Service. This includes but is not limited to your searches, 
                filters you use, and modules you use. 
            </p>
            <h2>Device Information</h2>
            <p>Click collects device information to improve your Click experience. This information includes but is not limited to recognizing 
                hardware and software information (such as your IP address), wireless and internet connection information, and device sensor 
                information. Click does not support the Do Not Track (DNT) browser extension. 
            </p>
            <h2>How We Use Information</h2>
            <p>We use the information collected to create and manage your account, to facilitate your app user experience, communicate with you 
                about our Service, linking your various devices and to ensure the workings of other important functions. Furthermore, we share 
                your public profile information and anything else you have posted publicly with other users and third parties who help us improve 
                our Service. Finally, we also share your information when required by law or if reasonably necessary to comply with a legal process 
                that would mitigate our liability in an actual or threatened lawsuit. 
            </p>
            <h2>How Long We Keep Your Information
            </h2>
            <p>We only keep your information as long as it is necessary for legitimate business purposes.
                
            </p>
            <h2>Your Rights
            </h2>
            <p>We will ask you to either consent to or opt out of the collection of specific types of device data. To be
                able to reassociate your device to your account, your unique device identifier will be stored with your
                account.
            </p>
            <h2>Children’s Privacy
            </h2>
            <p>Our Services are available to those 18 years old and above. We are strongly against users under the age
                of 18 on our platform. If you believe that a user is underage, please use our reporting system. </p>
            <h2>Revisions to the policy 
            </h2>
            <p>The policy can change at any given time.
            </p>
            <h2>User Data Deletion
            </h2>
            <p>Settings {'>'} Logins {'>'} Delete Account</p>
             <p>   Your user data will be deleted once you have confirmed account deletion.

            </p>
            
        </div>
    );
}

export default Privacy;
