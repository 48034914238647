import React from 'react';
import '../Home.css';
import logo from "../logo.svg";
import appStoreLogo from "../AppStore.svg";
import { BrowserView, MobileView } from 'react-device-detect';
import {Link} from 'react-router-dom';

function Home() {
  return (
    <>
      
      <BrowserView>
      
        <div className="App" >

          <style>{'body { background-color: #ffb400; }'}</style>

          <header className="App-header-web">

            <img src={logo} className="App-logo" alt="logo" />

            <a href="https://apps.apple.com/us/app/click-squad-up/id1604534600">
              <img src={appStoreLogo} className="App-store-logo" alt="logo" />
            </a>
            
            </header>

            <footer className="web-footer">
            
            <Link to="/terms" className="link">
                Terms of Service &nbsp;
            </Link>

            <Link to="/privacy" className="link">
                &nbsp; Privacy Policy
            </Link>
            
          </footer>

       
          </div>
      </BrowserView >


      
      <MobileView>
        <div className="App">
          <div className="App-header-mobile">
          <style>{'body { background-color: #ffb400; }'}</style>

            <a>
              <img src={logo} className="App-logo" alt="logo" />
            </a>
            <a href="https://apps.apple.com/us/app/click-fun-weekends/id1604534600">
              <img src={appStoreLogo} className="App-store-logo-mobile" alt="logo" />
            </a>

          </div>
          <footer className="web-footer">
            <Link to="/terms" className="link">
                Terms of Service &nbsp;
            </Link>
            <Link to="/privacy" className="link">
                &nbsp; Privacy Policy
            </Link>
            
          </footer>
        </div>
      </MobileView>
    </>
  );
}

export default Home;
