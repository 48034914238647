import React from 'react';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    useLocation,
} from 'react-router-dom';

import './App.css';
import Home from './pages/Home'
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
// import Raffle from './pages/Raffle';
import 'bootstrap/dist/css/bootstrap.min.css';


function App() {

    return (
        <Router>
            <div className="App">
                <Content />
            </div>
        </Router>
    )
}

function Content() {

    let location = useLocation()

    let isPricacy = location.pathname === "/privacy"
    let isTerms = location.pathname === "/terms"

    console.log(isPricacy, isTerms);

    return (
        <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/privacy" element={<Privacy />} />
            <Route exact path="/terms" element={<Terms />} />
        </Routes>
    )
}


export default App;